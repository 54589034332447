import changeLanguage from "@/components/changeLanguage.vue";
import { mapGetters } from "vuex";
import { URL } from "@/api/company";
import { URL as DASH_BOARD_URL } from "@/api/dashBoard.js";
import auth from "@/api/auth";
import { USER_ROLE_INDEX } from "@/consts";
import searchInput from "@/components/searchInput";
import UserIcon from "@/components/UserIcon";
import CompanySelect from "@/views/layouts/navbar/CompanySelect/CompanySelect";
import {formatDateTime} from "@/plugins/formatDate.js";

export default {
  components: {
    changeLanguage,
    searchInput,
    CompanySelect,
    UserIcon,
  },
  data() {
    return {
      typingTimer: 0,
      toggleUserDropdown: false,
      companySearch: {
        keySearch: null,
        page: 1,
      },
      searchUserName: null,
    };
  },
  async created() {
    window.addEventListener("blur", this.outfocus);
    window.addEventListener("focus", this.focus);

    this.companySearch = {
      keySearch: this.getCompanySearch, // Fetch data from local storage
      page: 1,
    };
    this.searchUserName = this.getUserSearch;

    // Init companies on null
    if (this.getCompanies.length == 0) {
      await this.getCompaniesInfo();
    }

    if (!this.getSelectedCompany.id && this.getCompanies.length > 0) {
      this.$store.dispatch("setSelectedCompany", this.getCompanies[0]);
    }
    await this.getUser();

    this.updateOnlineStatus();
    setInterval(() => {
      this.updateOnlineStatus();
    }, 30000);

    // Get data for Limit Search function
    this.getDetailDashboard();
  },

  methods: {
    outfocus() {
      this.$store.dispatch("setWindowFocusState", false);
    },
    focus() {
      this.$store.dispatch("setWindowFocusState", true);
    },

    async getDetailDashboard() {
      const param = {
        year: new Date().getFullYear(),
        company_id: this.getSelectedCompany.id,
      };

      const result = await this.$request({
        url: DASH_BOARD_URL.DASH_BOARD,
        params: param,
        ignore401: true,
      });

      const res = result.data;
      if (res.code == 200) {
        const dashboard = this.getDashboard;
        dashboard[this.getSelectedCompany.id] = res.data
        this.$store.dispatch("setDashboard", dashboard);
      }
    },

    async getUser() {
      const res = await this.$request({
        url: auth.GET_USER_INFO,
      });

      if (res.status == 200) {
        this.$store.dispatch("setAuthUser", res.data.data);
      }
    },

    updateOnlineStatus() {
      this.$request({
        url: auth.UPDATE_ONLINE_STATUS.replace(":id", this.getAuthUser.id),
        method: "POST",
      });
    },

    async signout() {
      const result = await this.$request({
        url: URL.LOGOUT,
        method: "POST",
      });
      const res = result.data;

      if (res.code == 200) {
        this.$store.dispatch("setToken", null);
        this.$store.dispatch("setSelectedCompany", {});
        this.$store.dispatch("setCompanies", []);
        this.$router.push({ name: "login" });
      }
    },

    showAlert() {
      this.$swal({
        text:
          this.getUserIndexRole < USER_ROLE_INDEX.COMPANY_SUPER_ADMIN_SUB
            ? "Vui lòng liên hệ quản lý để tạo công ty"
            : "Vui lòng tạo công ty",
        title: "Bạn chưa có công ty",
        icon: "warning",
        timer: "3000",
        width: "450",
        padding: "10px",
      }).then(() => {
        this.$router.push({ name: "companyManagement" });
        return;
      });
    },

    async getCompaniesInfo() {
      const params = {
        limit: 20,
        minimum: true,
        page: 1,
      };
      const result = await this.$request({ url: URL.LIST_COMPANY, params });
      const res = result.data;
      if (res.code == 200) {
        if (res.data && res.data.length >= 1) {
          this.$store.dispatch("setCompanies", res.data);
        } else {
          this.$store.dispatch("setCompanies", []);
        }
      }
    },

    searchInput() {
      this.$store.dispatch(
        "setCompanyKeySearch",
        this.companySearch.keySearch.trim()
      );
    },

    searchInputUserName() {
      this.$store.dispatch("setUserKeySearch", this.searchUserName);
    },

    formatNumber(number) {
      return new Intl.NumberFormat("de-DE").format(number);
    },

    invalidateCache() {
      this.$store.dispatch("updateProfileCacheDigest");
      window.location.reload()
    },
  },
  computed: {
    ...mapGetters([
      "getCompanies",
      "getSelectedCompany",
      "getAuthUser",
      "getDetailInvoice",
      "getDashboard",
      "getProfileCacheDigest",

      // Keep Search
      "getCompanySearch",
      "getUserSearch",
    ]),
    routeName() {
      return this.$route.name;
    },
    routeWithoutCompanySelected() {
      const routes = [
        "companyManagement",
        // "invoice_summary",
        "profile",
        "editInvoices",
        "system-statistic"
      ];
      return routes.indexOf(this.$route.name) != -1;
    },
    user() {
      const is_owner =
        this.getAuthUser.id == this.getSelectedCompany.owner.id &&
        !this.routeWithoutCompanySelected;
      return {
        ...this.getAuthUser,
        is_owner,
      };
    },
    cacheDate() {
      return formatDateTime(this.getProfileCacheDigest);
    }
  },

  watch: {
    getSelectedCompany(newVal) {
      const company_selected = this.getCompanies.filter(
        (company) => company.id == newVal
      );
      if (company_selected.length) {
        this.selectCompany = company_selected[0];
      }
      this.$store.dispatch("setIsManual", false);
      this.$store.dispatch("setIsUpdating", false);
      this.$store.dispatch("resetInvoicePaginatePage");
      this.$store.dispatch("resetInvoiceLogPaginatePage");
      this.$store.dispatch("resetMailBoxPaginatePage");

      // Update role permission
      this.getUser();
      this.getDetailDashboard();
    },

    async $route() {
      this.companyKeySearch = this.getCompanySearch;
      this.searchUserName = this.getUserSearch;

      // Disable updating if route changed
      this.$store.dispatch("setIsUpdating", false);
      this.$store.dispatch("setIsManual", false);

      // For checking company is null on route changed
      if (!this.getSelectedCompany && this.getCompanies.length > 0) {
        this.$store.dispatch("setSelectedCompany", this.getCompanies[0]);
      }
    },
  },
};
